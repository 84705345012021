import useJwt from '@/auth/jwt/useJwt'
import { API_ROUTES } from '../config/routes/api'

export default {
	async getAll(service_id) {
		const params = {
			order: 'asc',
			order_by: 'name',
			offset: 0,
			limit: 200,
			'filters[0][field]': 'service_id',
			'filters[0][operator]': '=',
			'filters[0][value]': service_id,
		}
		const { data } = await useJwt.get(`${API_ROUTES.area.get}`, params)

		return data
	},
	async find(id) {
		const { data } = await useJwt.get(`${API_ROUTES.area.get}/${id}`)

		return data
	},
	save(service_id, id, data) {
		const url = API_ROUTES.area.save
			.replace(':service_id', service_id)
			.replace(':id', id)
		return useJwt.put(url, data)
	},
	delete(service_id, id) {
		const url = API_ROUTES.area.delete
			.replace(':service_id', service_id)
			.replace(':id', id)
		return useJwt.delete(url)
	},
	update(service_id, id, data) {
		const url = API_ROUTES.area.update
			.replace(':service_id', service_id)
			.replace(':id', id)
		return useJwt.patch(url, data)
	},
}
